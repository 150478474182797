import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import QrRegister from "./qrRegister";
import QrTabs from "./qrTabs";
import QrChecker from "./qrChecker";
import QrLastRegistered from "./useLastRegisteredQrCode";
import { Modal } from "antd";
import getLastRegisteredQrCode from "./getLastRegisteredQrCode";

const QrRegisterCheckerModal = ({
  openQrModal,
  handleCancel,
  toggleRefetchData,
}) => {
  const [selectedOption, setSelectedOption] = useState("registerQr");
  const isSelectedOptionToRegisterQr = selectedOption === "registerQr";
  const isSelectedOptionToCheckQr = selectedOption === "checkQr";
  const [isLoading, setIsLoading] = useState(false);

  // Use the custom hook
  const { lastRegisteredQrCode, loadData, setLastRegisteredQrCode } =
    getLastRegisteredQrCode(setIsLoading);

  const handleSelectOption = (selectedTabOption) => {
    //For selecting what form input to be displayed
    setSelectedOption(selectedTabOption);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Modal
        open={openQrModal}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        footer={null}
        width={700}
      >
        {isLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderRadius: "0.5rem",
            }}
          />
        )}
        <QrTabs
          handleSelectOption={handleSelectOption}
          isSelectedOptionToRegisterQr={isSelectedOptionToRegisterQr}
          isSelectedOptionToCheckQr={isSelectedOptionToCheckQr}
          isLoading={isLoading}
        />
        <div className="w-full flex">
          {isSelectedOptionToRegisterQr ? (
            <div className="w-1/2">
              <QrRegister
                isSelectedOptionToRegisterQr={isSelectedOptionToRegisterQr}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setLastRegisteredQrCode={setLastRegisteredQrCode}
                loadData={loadData}
                toggleRefetchData={toggleRefetchData}
                handleCancel={handleCancel}
              />
            </div>
          ) : (
            <div className="w-1/2">
              <QrChecker
                isSelectedOptionToCheckQr={isSelectedOptionToCheckQr}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </div>
          )}

          <div className="w-1/2">
            <QrLastRegistered
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              lastRegisteredQrCode={lastRegisteredQrCode}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QrRegisterCheckerModal;
