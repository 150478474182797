import { Button } from "@mui/material";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createQrCode } from "store";

const QrRegister = ({
  isSelectedOptionToRegisterQr,
  setIsLoading,
  isLoading,
  loadData,
  toggleRefetchData,
  handleCancel,
}) => {
  const [formInputs, setFormInputs] = useState({
    fromQRCode: "",
    toQRCode: "",
  });
  const [isFormDirty, setIsFormDirty] = useState(false);
  const startQrCodeInputRef = useRef();
  const endQrCodeInputRef = useRef();
  const isStartQrCodeInputEmpty = isFormDirty && !formInputs.fromQRCode;
  const isEndQrCodeInputEmpty = isFormDirty && !formInputs.toQRCode;
  const dispatch = useDispatch();
  const characterInputLimit = 14;
  const startingQrNumber = parseInt(formInputs.fromQRCode.split("-")[1]);
  const endQrNumber = parseInt(formInputs.toQRCode.split("-")[1]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({
      ...formInputs,
      [name]: value.slice(0, characterInputLimit),
    });
  };

  const handleClearInputFields = () => {
    setFormInputs({
      fromQRCode: "",
      toQRCode: "",
    });
  };

  //Auto focus first the first input field when the page loads or if it is selected
  useEffect(() => {
    if (isSelectedOptionToRegisterQr) {
      startQrCodeInputRef.current.focus();
    }
    if (formInputs.fromQRCode.length === characterInputLimit) {
      endQrCodeInputRef.current.focus();
    }
  }, [isSelectedOptionToRegisterQr, formInputs]);

  const focusInputFields = () => {
    if (!formInputs.fromQRCode) {
      startQrCodeInputRef.current.focus();
    } else if (!formInputs.toQRCode) {
      endQrCodeInputRef.current.focus();
    } else {
      startQrCodeInputRef.current.focus();
    }
  };

  //Registration of qr code
  const handleSubmit = (e) => {
    focusInputFields();
    e.preventDefault();
    if (!formInputs.fromQRCode || !formInputs.toQRCode) {
      return setIsFormDirty(true); //Form validation to check if the input fields are empty
    } else if (endQrNumber < startingQrNumber) {
      return [
        notification.error({
          message: "Failed to register Qr Code",
          description:
            "Ending qr code number must be greater than the starting qr code number",
        }), //Check if the ending qr code number is greater than the first qr code number
        handleClearInputFields(), //Clear the input fields when warning occurs
      ];
    } else {
      if (createQrCode.pending().type === "qr-code/register-qrcode/pending") {
        setIsLoading(true);
      }
      dispatch(createQrCode(formInputs)).then((response) => {
        const isFetchSuccess = response?.payload?.success;
        if (isFetchSuccess) {
          notification.success({
            message: "Registered Qr Code",
            description: response?.payload?.successMessage,
          });
          setIsFormDirty(false);
          handleClearInputFields(); //Clear the input fields after qr code is successfully registered
          setIsLoading(false);
          loadData();
          toggleRefetchData();
          handleCancel();
        }
        if (!isFetchSuccess) {
          //Check if the request fails, then display an error notification
          notification.error({
            message: "Failed to register Qr Code",
            description: errorMessagesDescription(
              response?.payload?.response?.data?.errorMessages
            ),
          });
          setIsLoading(false);
          handleClearInputFields(); //Clear the input fields after qr code is successfully registered
        }
      });
    }
  };

  return (
    <div
      className={`mt-[2.2rem] px-4 ${
        !isSelectedOptionToRegisterQr && "scale-0 hidden"
      } duration-300 ease-in-out`}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-1">
        <label className="required">Starting Qr Code</label>
        <input
          value={formInputs.fromQRCode}
          ref={startQrCodeInputRef}
          onChange={handleInputChange}
          name="fromQRCode"
          className={`${
            isStartQrCodeInputEmpty
              ? "border border-red-600 focus:border-red-600"
              : " focus:border-blue-500"
          } rounded-md py-2 px-2 border border-black  focus:outline-none`}
          type="text"
        />
        {isStartQrCodeInputEmpty && (
          <p className="text-red-600 text-xs">Starting Qr Code is required</p>
        )}
        <label className="mt-3 required">Ending Qr Code</label>
        <input
          value={formInputs.toQRCode}
          ref={endQrCodeInputRef}
          onChange={handleInputChange}
          name="toQRCode"
          className={`${
            isEndQrCodeInputEmpty
              ? "border border-red-600 focus:border-red-600"
              : " focus:border-blue-500"
          } rounded-md py-2 px-2 border border-black focus:border-blue-500 focus:outline-none`}
          type="text"
        />
        {isEndQrCodeInputEmpty && (
          <p className="text-red-600 text-xs">Ending Qr Code is required</p>
        )}
        <Button
          disabled={isLoading}
          sx={{ width: "100%", mt: 2 }}
          type="submit"
          variant="contained"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default QrRegister;
