import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { EmptyData } from "components/jkt/global/emptyData";
import { getUserInfoById } from "store";

const UseLastRegisteredQrCode = ({ setIsLoading, lastRegisteredQrCode }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  const loadUserData = useCallback(
    (id) => {
      if (getUserInfoById.pending().type === "get-user-info-by-id/pending") {
        setIsLoading(true);
      }
      dispatch(getUserInfoById(id)).then((element) => {
        if (element?.payload?.success) {
          setIsLoading(false);
          setUser(element.payload.data);
        }
      });
    },
    [dispatch]
  );

  const renderEmptyData = () => <EmptyData description="No Qr Code Detected" />;

  useEffect(() => {
    if (lastRegisteredQrCode) {
      loadUserData(lastRegisteredQrCode.createdBy);
    }
  }, [lastRegisteredQrCode]);

  return (
    <>
      {lastRegisteredQrCode ? (
        <div className="mt-[2.2rem] px-4 flex flex-col w-full justify-between">
          <p className="text-lg font-medium ">Latest Qr Registered</p>
          <p>ID: {lastRegisteredQrCode.qrCode}</p>
          <p>Registered by: {user.displayName}</p>
          <p>
            Date/Time:{" "}
            {moment(lastRegisteredQrCode.dateCreated).format(
              "MMMM D, YYYY/h:mm:ss a"
            )}
          </p>
        </div>
      ) : (
        <div className="mt-8">{renderEmptyData()}</div>
      )}
    </>
  );
};

export default UseLastRegisteredQrCode;
