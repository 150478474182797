import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const updateSampleCollector = createAsyncThunk(
  "sample-collectors/update-sample-collector",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const isProjectLocationJkt =
        process.env.REACT_APP_COUNTRY.toLowerCase() === "jkt";

      const response = await api.put(URL_SAMPLE_COLLECTORS + "/" + data[1], {
        id: data[1],
        licenseNumber: isProjectLocationJkt ? null : data[0].licenseNumber,
        licenseExpirationDate: isProjectLocationJkt
          ? null
          : data[0].licenseExpirationDate,
        firstName: data[0].firstName,
        lastName: data[0].lastName,
        collectorType: data[0].collectorType,
        employeeId: isProjectLocationJkt ? null : data[0].employeeId,
        clientId: clientId,
        phoneNumber: isProjectLocationJkt ? null : data[0].phoneNumber,
        email: isProjectLocationJkt ? null : data[0].email,
        employmentStartDate: isProjectLocationJkt
          ? null
          : data[0].employmentStartDate,
        employmentEndDate: isProjectLocationJkt
          ? null
          : data[0].employmentEndDate,
        isActive: data[0].isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const requestUpdateSampleCollector = createAsyncThunk(
  "sample-collectors/req-update-sample-collectors",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.put(URL_SAMPLE_COLLECTORS + "/" + data.id, {
        id: data.id,
        licenseNumber: data.licenseNumber,
        licenseExpirationDate: data.licenseExpirationDate,
        firstName: data.firstName,
        lastName: data.lastName,
        collectorType: data.collectorType,
        employeeId: data.employeeId,
        clientId: +data.clientId,
        phoneNumber: data.phoneNumber,
        email: data.email,
        employmentStartDate: data.employmentStartDate,
        employmentEndDate: data.employmentEndDate,
        isActive: data.isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateSampleCollector, requestUpdateSampleCollector };
