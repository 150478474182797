import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

// This api call is being used in request page to update physician/healthcare provider

const updateHealthcareProvider = createAsyncThunk(
  "healthcareProviders/update-healthcare-provider",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();
      const isProjectLocationJkt =
        process.env.REACT_APP_COUNTRY.toLowerCase() === "jkt";

      const response = await api.put(URL_HEALTHCARE_PROVIDER + "/" + data.id, {
        id: data.id,
        firstName: data.firstName,
        licenseNumber: isProjectLocationJkt ? null : data.licenseNumber,
        licenseExpirationDate: isProjectLocationJkt
          ? null
          : data.licenseExpirationDate,
        lastName: data.lastName,
        title: isProjectLocationJkt ? null : data.title,
        specialty: isProjectLocationJkt ? null : data.specialty,
        phoneNumber: isProjectLocationJkt ? null : data.phoneNumber,
        email: isProjectLocationJkt ? null : data.email,
        clientId: clientId,
        isActive: data.isActive,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateHealthcareProvider };
