import React from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  EditFilled,
  FileTextOutlined,
  PlusCircleFilled,
  ShoppingFilled,
  StopFilled,
  WarningFilled,
  QuestionCircleOutlined,
  TruckOutlined,
  FileDoneOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Box, Divider, Tooltip } from "@mui/material";
import { Space, Timeline } from "antd";
import CreatedRequestData from "./createdRequestData";
import renderDetailsText from "utils/renderDetailsText";
import AfterModificationData from "./afterModificationData";
import BeforeModificationData from "./beforeModificationData";
import CreatedNotesData from "./createdNotesData";
import AfterNotesModificationData from "./afterNotesModificationData";
import BeforeNotesModificationData from "./beforeNotesModificationData";

const AuditTrailDisplay = ({
  auditTrailData,
  showAuditTrailDetails,
  handleToggleAuditTrailDetails,
}) => {
  const renderDotColorAndIcon = (actionPerformed) => {
    const dotColorMap = {
      "Created Request": {
        color: "gray",
        icon: <PlusCircleFilled className="text-lg" />,
      },
      "Picked Up": {
        color: "blue",
        icon: <ShoppingFilled className="text-lg" />,
      },
      "Cancelled Request": {
        color: "red",
        icon: <CloseCircleFilled className="text-lg" />,
      },
      "Accepted Request": {
        color: "green",
        icon: <CheckCircleFilled className="text-lg" />,
      },
      "Hold Request": {
        color: "#F7D100",
        icon: <WarningFilled className="text-lg" />,
      },
      "Modified Request": {
        color: "#ED6C02",
        icon: <EditFilled className="text-lg" />,
      },
      "Rejected Request": {
        color: "red",
        icon: <StopFilled className="text-lg" />,
      },
      "Notes updated": {
        color: "blue",
        icon: <FileTextOutlined className="text-lg" />,
      },
      "In Transit": {
        color: "blue",
        icon: <TruckOutlined className="text-lg" />,
      },
      "Completed Delivery": {
        color: "#96d952",
        icon: <FileDoneOutlined CloseSquareOutlinedclassName="text-lg" />,
      },
      "Missed Pickup": {
        color: "#ED6C02",
        icon: <CloseSquareOutlined className="text-lg" />,
      },
    };

    const selectedDot = dotColorMap[actionPerformed];

    if (selectedDot === undefined) {
      // Fallback value
      return {
        color: "gray",
        icon: <QuestionCircleOutlined className="text-lg" />,
      };
    }
    return {
      color: selectedDot.color,
      icon: selectedDot.icon,
    };
  };

  const renderTimelineLabel = (data, index) => {
    const showAuditTrail = showAuditTrailDetails(index);
    const isStatusModifiedRequest = data?.action === "Modified Request";
    const isStatusNotesUpdated = data?.action === "Notes updated";
    const isNotesModified = isStatusNotesUpdated && data?.oldValue !== null;

    return (
      <Box>
        <p>{moment(data?.auditDate).format("LLL")}</p>
        {(isStatusModifiedRequest || isNotesModified) && (
          <Box
            sx={{
              mt: 1,
              display: showAuditTrail ? "flex" : "none",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              textAlign: "start",
              border: "1px solid #F0F0F0",
              borderRadius: "0.3rem",
              p: 1,
            }}
          >
            <Divider sx={{ fontSize: 15, width: "100%" }}>
              <span className="font-bold">Before</span>
            </Divider>
            {isStatusModifiedRequest && (
              <BeforeModificationData
                oldValue={JSON.parse(data?.oldValue)}
                newValue={JSON.parse(data?.newValue)}
              />
            )}
            {isNotesModified && !isStatusModifiedRequest && (
              <BeforeNotesModificationData oldNotes={data?.oldValue} />
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderTimelineChildren = (data, index) => {
    const isStatusCreatedRequest = data?.action === "Created Request";
    const isStatusModifiedRequest = data?.action === "Modified Request";
    const isStatusCancelled = data?.action === "Cancelled Request";
    const isStatusRejected = data?.action === "Rejected Request";
    const isStatusHold = data?.action === "Hold Request";
    const isStatusAccepted = data?.action === "Accepted Request";
    const isStatusPickedUp = data?.action === "Picked Up";
    const isStatusNotesUpdated = data?.action === "Notes updated";
    const isStatusInTransit = data?.action === "In Transit";
    const isStatusMissedPickup = data?.action === "Missed Pickup";
    const isStatusCompletedDelivery = data?.action === "Completed Delivery";
    const isNotesModified = isStatusNotesUpdated && data?.oldValue !== null;
    const showDropdownIcon =
      !isStatusAccepted &&
      !isStatusPickedUp &&
      !isStatusInTransit &&
      !isStatusCompletedDelivery &&
      !isStatusMissedPickup;

    const showAuditTrail = showAuditTrailDetails(index);
    const dataToBeStored = {
      id: index,
      action: data?.action,
      newValue: data?.newValue,
    };

    return (
      <Box>
        <Space size="small">
          <p className="font-semibold">
            {data?.userDisplayName} - {data?.action}
          </p>
          {showDropdownIcon && (
            <Tooltip
              placement="right"
              title={showAuditTrail ? "Hide Details" : "View Details"}
              arrow
            >
              <ArrowBackIosRoundedIcon
                onClick={() => handleToggleAuditTrailDetails(dataToBeStored)}
                sx={{
                  fontSize: "1.1rem",
                  transform: showAuditTrail
                    ? "rotate(-90deg)"
                    : "rotate(90deg)",
                  cursor: "pointer",
                  transition: "0.3s ease-in-out",
                  mb: "3px",
                  p: "0.1rem",
                  "&:hover": {
                    backgroundColor: "#BFBFBF",
                    transition: "0.3s ease-in-out",
                    borderRadius: "0.2rem",
                  },
                }}
              />
            </Tooltip>
          )}
        </Space>
        <Box
          sx={{
            mt: 1,
            display: showAuditTrail ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            textAlign: "start",
            border: "1px solid #F0F0F0",
            borderRadius: "0.3rem",
            p: 1,
            width: "100%",
          }}
        >
          {(isStatusModifiedRequest || isNotesModified) && (
            <Divider sx={{ fontSize: 15, width: "100%" }}>
              <span className="font-bold">After</span>
            </Divider>
          )}
          {isStatusCancelled &&
            renderDetailsText("Cancellation Reason", data?.reason)}
          {isStatusRejected &&
            renderDetailsText("Rejection Reason", data?.reason)}
          {isStatusHold && renderDetailsText("Held Reason", data?.reason)}
          {isStatusCreatedRequest && (
            <CreatedRequestData newValue={JSON.parse(data?.newValue)} />
          )}
          {!isNotesModified &&
            !isStatusCreatedRequest &&
            !isStatusModifiedRequest && (
              <CreatedNotesData testOrderNotes={data?.newValue} />
            )}
          {isStatusModifiedRequest && (
            <AfterModificationData
              oldValue={JSON.parse(data?.oldValue)}
              newValue={JSON.parse(data?.newValue)}
            />
          )}
          {isNotesModified && !isStatusModifiedRequest && (
            <AfterNotesModificationData newNotes={data?.newValue} />
          )}
        </Box>
      </Box>
    );
  };

  const auditTrailTimelineItems = auditTrailData?.map((data, index) => ({
    label: renderTimelineLabel(data, index),
    children: renderTimelineChildren(data, index),
    color: renderDotColorAndIcon(data?.action)?.color,
    dot: renderDotColorAndIcon(data?.action)?.icon,
  }));

  return (
    <Box sx={{ px: 4, width: "100%", height: "100%", mt: 2 }}>
      <Timeline className="mt-10" mode="left" items={auditTrailTimelineItems} />
    </Box>
  );
};

export default AuditTrailDisplay;
